import {PublicFooter, PublicNavbar} from "../public_components/PublicNavComponents";
import {useEffect, useState} from "react";
import {get_buckets, submit_ticket} from "../api/api_functions";


function PublicPage(){
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedBucket, setSelectedBucket] = useState('');
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');

    const submitForm = async (e) =>{
        e.preventDefault(true);
        if(selectedBucket === '') {
            return;
        }


        const data_body = {
            bucket: selectedBucket,
            name: username,
            message: message
        }
        const req = await submit_ticket(data_body);
        if(req === 200){
            setMessage('');
            setUsername('');
            setSelectedBucket('');
        }

    }

    useEffect(() => {

        const fetchData = async () => {
            // Get uuid from backend
            const response = await get_buckets();
            setData(await response);
            setLoading(false);

        };

        fetchData().then();

    },[]);
    if(loading){
        return (
            <div className="flex w-52 flex-col gap-4">
                <div className="skeleton h-32 w-full"></div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
            </div>
        )
    }
    return(
        <div>
            <PublicNavbar/>
            <div className="w-full flex justify-center">
                <div className="padding-0-50 flex">
                    <div className="justify-center flex">
                        <h1 className="text-2xl justify-center flex padding-50-50">
                            Bitte teile uns dein Anliegen mit wir werden uns schnellst möglich darum kümmern
                        </h1>
                    </div>
                </div>
            </div>
            <div>
                <form onSubmit={submitForm}>
                    <div className="flex justify-center">
                        <select className="select select-bordered w-full max-w-xs grow justify-center"
                                value={selectedBucket}
                                onChange={(e) => {
                                    setSelectedBucket(e.target.value);
                                }}>
                            <option value=''>Please Select</option>
                            {data.map((singleOption) => (
                                // eslint-disable-next-line react/jsx-key
                                <option key={singleOption.id}
                                        value={singleOption.url_name}>{singleOption.ui_name}</option>
                            ))}
                            required
                        </select>
                    </div>
                    <div className="padding-10-10 flex justify-center"/>
                    <div className="flex justify-center">
                        <input type="text"
                               placeholder="Benutzername"
                               className="input input-bordered w-full max-w-xs grow justify-center"
                               value={username}
                               onChange={(e) => {
                                   setUsername(e.target.value);
                               }}
                               required
                        />
                    </div>
                    <div className="padding-10-10"/>
                    <div className="flex justify-center">
                        <textarea
                            className="textarea textarea-bordered w-1/6 max-w-ws flex justify-center"
                            placeholder="Beschreibe dein Anliegen (optinal)"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex justify-center">
                        <div className="padding-10-10">
                            <button className="btn btn-neutral">Send Request</button>
                        </div>
                    </div>
                </form>
            </div>
            <PublicFooter/>
        </div>
    )
}

export default PublicPage;