import {Link} from "react-router-dom";


function PublicNavbar(){

    return (
        <div className="navbar bg-base-300">
            <a className="btn btn-ghost text-xl"><Link to="/dashboard">Die Platform Service Desk</Link></a>
        </div>
    )
}

function PublicFooter(){
    return (
        <footer className="footer footer-center bg-base-300 text-base-content p-4">
            <aside>
                <p>Copyright © 2024 - {new Date().getFullYear()} - All right reserved by Connor Molz</p>
            </aside>
        </footer>
    )
}

export {PublicNavbar, PublicFooter}