import {useKeycloak} from "@react-keycloak/web";

function Login(){
    const { keycloak, initialized } = useKeycloak();
    return(
        <div className="bg-base-100 flex">
            <h1 className="text-8xl flex justify-center">
                <div className="hover:text-gray-200">
                    {!keycloak.authenticated && (
                        <button
                            type="button"
                            className="text-blue-800"
                            onClick={() => keycloak.login()}
                        >
                            Login
                        </button>
                    )}

                    {!!keycloak.authenticated && (
                        <button
                            type="button"
                            className="text-blue-800"
                            onClick={() => keycloak.logout()}
                        >
                            Logout ({keycloak.tokenParsed.preferred_username})
                        </button>
                    )}
                </div>
            </h1>
        </div>
    )
}

export default Login;