import {InternalFooter, InternalNavbar} from "../internal_components/InternalNavComponents";
import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_buckets} from "../api/api_functions";



const Dashboard = () =>{
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            const response = await get_buckets();
            setData(await response);
            setLoading(false);
        };

        fetchData().then();
    }, []);
    if(loading){
        return (
            <div className="flex w-52 flex-col gap-4">
                <div className="skeleton h-32 w-full"></div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
            </div>
        )
    }
    return(

        <div>
            <InternalNavbar/>
            {data.map((item) => (
                <Link to={"/dashboard/" + item.url_name}>
                    <div className="card bg-base-200 rounded-box grid h-20 place-items-center p-4 btn">
                        {item.ui_name}
                    </div>
                </Link>
            ))}
            <InternalFooter/>
        </div>
    )
}

export default Dashboard;