import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_bucket_ui_name, get_status_as_text, get_tickets_from_bucket} from "../api/api_functions";
import {InternalFooter, InternalNavbar} from "../internal_components/InternalNavComponents";

function Bucket(){
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const showStatusName = async (status_nr) => await get_status_as_text(status_nr);
    const { bucket } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const[bucketName, setBucketName] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            let response = await get_tickets_from_bucket(bucket);
            for (let i = 0; i < response.length; i++) {
                console.log(response[i].status);
                response[i].status = await showStatusName(response[i].status);
            }
            setData(await response);
            setLoading(false);
            const bucket_name = await get_bucket_ui_name(bucket);
            setBucketName(bucket_name);

        };

        fetchData().then();
    }, []);
    if(loading){
        return (
            <div className="flex w-52 flex-col gap-4">
                <div className="skeleton h-32 w-full"></div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
            </div>
        )
    }
    return (
        <div>
            <InternalNavbar/>
            <div className="justify-center flex">
                <h classname="padding-10-10 text-4xl">{bucketName}</h>
            </div>
            <div className="overflow-x-auto">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Ticket id</th>
                        <th>Username / Requested Username</th>
                        <th>Message from the user</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item) => (
                            <tr className="hover" onClick={() => {
                                navigate("/dashboard/" + bucket + "/" + item.ticketNr)
                            }}>

                                <th>{zeroPad(item.ticketNr, 8)}</th>
                                <td>{item.name}</td>
                                <td>{item.message}</td>
                                <td>{item.status}</td>

                            </tr>

                        ))
                    }
                    </tbody>
                </table>
            </div>
            <InternalFooter/>
        </div>
    )

}

export default Bucket;