import Keycloak from "keycloak-js";

const keycloak = () =>{

    /*return new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    })*/
    return new Keycloak({
        url: "https://auth.cmotctest.ipv64.net",
        realm: "service_desk",
        clientId: "service_desk"
    })
}



export default keycloak;