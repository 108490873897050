import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import PublicPage from "./public_page/PublicPage";
import Dashboard from "./internal/Dashboard";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import PrivateRoute from "./helpers/PrivateRoute";
import Login from "./public_page/Login";
import Bucket from "./internal/Bucket";
import Ticket from "./internal/Ticket";
import keycloak from "./Keycloak";


function App() {
    return (
        <div>
            <ReactKeycloakProvider authClient={ keycloak() }>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<PublicPage/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/dashboard" element={
                            <PrivateRoute>
                                <Dashboard/>
                            </PrivateRoute>
                        }/>
                        <Route path='/dashboard/:bucket' element={
                            <PrivateRoute>
                                <Bucket/>
                            </PrivateRoute>
                        }/>
                        <Route path='/dashboard/:bucket/:id' element={
                            <PrivateRoute>
                                <Ticket/>
                            </PrivateRoute>
                        }/>

                    </Routes>
                </BrowserRouter>
            </ReactKeycloakProvider>
        </div>
    );
}

export default App;
