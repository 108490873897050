import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";

function InternalFooter(){
    return (
        <footer className="footer footer-center bg-base-300 text-base-content p-4">
            <aside>
                <p>Copyright © 2024 - {new Date().getFullYear()} - All right reserved by Connor Molz</p>
            </aside>
        </footer>
    );
}

function InternalNavbar() {
    const {keycloak, initialized } = useKeycloak();
    return (
        <div className="navbar bg-base-300">
            <div className="flex-1">
                <Link to="/dashboard" className="btn btn-ghost text-xl">Die Platform Service Desk</Link>
            </div>
            <div className="flex-none">
                <ul className="menu menu-horizontal px-1">
                    <li>
                        <button
                            type="button"
                            className="text-blue-800"
                            onClick={() => keycloak.logout()}
                        >
                            Logout ({keycloak.tokenParsed.preferred_username})
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export {InternalFooter, InternalNavbar}