import {useNavigate, useParams} from "react-router-dom";
import {InternalFooter, InternalNavbar} from "../internal_components/InternalNavComponents";
import {useEffect, useState} from "react";
import {
    add_comment,
    get_all_status,
    get_bucket_ui_name,
    get_status_as_text,
    get_ticketset,
    update_status
} from "../api/api_functions";
import {useKeycloak} from "@react-keycloak/web";

const Ticket = () => {
    const {keycloak, initialized } = useKeycloak();
    const { bucket, id } = useParams();
    const [ticket, setTicket] = useState(null);
    const [comments, setComments] = useState(null);
    const [uiName, setUIName] = useState(bucket);
    const [loading, setLoading] = useState(true);
    const [userComment, setUserComment] = useState(null);
    const [newCommentIsIn, setNewCommentIsIn] = useState(false);
    const [newStatus, setNewStatus] = useState(null);
    const [allStatus, setAllStatus] = useState(null);
    const navigate = useNavigate();

    async function sendComment(e){
        e.preventDefault();
        const data_package = {
            id: id,
            insertedComment: userComment,
            user: keycloak.tokenParsed.preferred_username
        };
        const reqStat= await add_comment(data_package);
        if(reqStat === 200){
            setUserComment("");
            setNewCommentIsIn(true);
        }
    }

    const submitNewStatus = async (e) =>{
        e.preventDefault();
        const data_package = {
            id: id,
            newStatus: newStatus,
        }
        const status = await update_status(data_package);
        if(status === 200){
            setNewCommentIsIn(true);
        }

    }

    const formatDate = (date) =>{

        return date.toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setNewCommentIsIn(false);
                setLoading(true);
                const ticketData = await get_ticketset(id);
                const uiNameData = await get_bucket_ui_name(bucket);
                const statusData = await get_all_status();

                ticketData.ticket.status = await get_status_as_text(ticketData.ticket.status);
                setTicket(ticketData.ticket);
                setUIName(uiNameData);
                setAllStatus(statusData);
                setNewStatus(ticketData.ticket.status)
                // Assuming comments come with the ticket data
                setComments(ticketData.comments);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData().then();
    }, [bucket, id, newCommentIsIn]);

    if (loading) {
        return (
            <div className="flex w-52 flex-col gap-4">
                <div className="skeleton h-32 w-full"></div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
            </div>
        );
    }

    return (
        <>
            <InternalNavbar />
            <div className="hero bg-base-100 min-h-screen">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <div className="p-4">
                            <h1 className="text-4xl w-full padding-10-10">{uiName + ":   " + ticket.name}</h1>
                            <p className="text-2xl flex justify-center block-text">{ticket.message}</p>
                        </div>
                        <div>
                            <h1 className="text-2xl padding-10-10">Current Status: {ticket.status}</h1>
                        </div>
                        <button className="btn" onClick={() => document.getElementById('my_modal_1').showModal()}>
                            Change status
                        </button>
                        <dialog id="my_modal_1" className="modal">
                            <div className="modal-box">
                                <h3 className="font-bold text-lg">Current Status : {ticket.status}</h3>
                                <p className="py-4">Select new status</p>
                                <div className="modal-action">
                                    <form method="dialog" onSubmit={submitNewStatus}>
                                        <select className="select select-bordered w-full max-w-xs"
                                                value={newStatus}
                                                onChange={(e) => setNewStatus(e.target.value)}
                                        >
                                            {
                                                allStatus.map((singleOption) => (
                                                    <option value={singleOption}>{singleOption}</option>
                                                ))
                                            }
                                        </select>
                                        <button className="btn">Close</button>
                                    </form>
                                </div>
                            </div>
                        </dialog>
                        <div className="divider padding-50-50"/>
                        <h className="text-2xl">Add a Comment to the ticket</h>
                        <div>
                            <form onSubmit={sendComment}>
                                <div>
                                    <div>
                                        <textarea className="textarea textarea-bordered"
                                                  placeholder="Comment"
                                                  value={userComment}
                                                  onChange={(e) => {
                                                      setUserComment(e.target.value)
                                                  }
                                                  }
                                        ></textarea>
                                    </div>
                                    <div>
                                        <button className="btn">Send Comment</button>
                                    </div>
                                </div>
                            </form>
                            <div className="divider padding-10-10"/>
                            {
                                comments.map((comment) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div className="chat chat-start">
                                        <div className="chat-header">
                                            <time className="text-xs opacity-50">
                                                {formatDate(comment.commentDate) + "   by: " + comment.user}
                                            </time>
                                        </div>
                                        <div className="chat-bubble">{comment.commentText}</div>
                                        <div className="chat-footer opacity-50"></div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
            <InternalFooter/>
        </>
    );
};

export default Ticket;
