async function get_buckets(){
    const req = await fetch("/api/get_buckets");
    return await req.json();
}

async function submit_ticket(body){
    const req_options = {
        method: "POST",
        headers:{
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body),
    }
    const req = await fetch("/api/add_ticket",req_options);
    return req.status;
}

async function get_tickets_from_bucket(bucket_as_string){
    const req_options = {
        method: "GET",
        headers: {
            "accept": "application/json",
        },
    }
    const req = await fetch(
        "/api/get_tickets_from_bucket?bucket=" + bucket_as_string,
        req_options
    );
    return await req.json();
}

async function get_ticketset(id){
    const req_options = {
        method: "GET",
        headers:{
            "accept": "application/json",
        }
    };
    const req = await fetch("/api/get_ticket?id="+id, req_options);
    return await req.json();
}

async function get_bucket_ui_name(bucket_url_name){
    const req_options = {
        method: "GET",
    }
    const req = await fetch("/api/get_bucket_ui_name?bucket=" + bucket_url_name, req_options);
    return await req.text();
}

async function add_comment(data_package){
    const req_body = {
        ticket_nr: data_package.id,
        comment: data_package.insertedComment,
        date: Date(),
        user: data_package.user
    };
    const req_options = {
        method: "POST",
        headers:{
            "Content-Type": "application/json",
        },
        body: JSON.stringify(req_body)
    }

    const req = await fetch("/api/add_comment",req_options);
    if(req.status === 200){
        return 200;
    }
}

async function get_status_as_text(status_id){
    const req_options = {
        method: "GET",
    }
    const req = await fetch("/api/get_status_as_text?id="+status_id, req_options);
    if(req.status === 200){
        return await req.text();
    }
}

async function update_status(data_package){
    const req_options = {
        method: "PUT",
        headers:{
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data_package)
    }
    const req = await fetch("/api/update_status", req_options);
    if(req.status === 200){
        return 200;
    }
    return 1;
}

async function get_all_status(){
    const req_options = {
        method: "GET"
    }
    const req = await fetch("/api/get_all_status", req_options);
    return await req.json();
}

export {
    get_buckets,
    submit_ticket,
    get_tickets_from_bucket,
    get_ticketset,
    get_bucket_ui_name,
    add_comment,
    get_status_as_text,
    update_status,
    get_all_status
};